<template>
    <div class="box">
        <h2>
            订单列表
            <div class="switchTab">
                <span v-for="item in orderStatusList" :key="item.value" :class="orderStatus == item.value ? 'act' : ''" class="tab-item" @click="getOrderList(item.value)">
                    {{ item.label }}
                    <span class="number">（{{ memberInfo[item.value] }}）</span>
                </span>
            </div>
        </h2>
        <div class="form-row">
            <div class="form-item" style="margin-right: 30px">
                <span>搜索订单</span>
                <el-input size="small" style="width: 150px" v-model="keyword" name="keyword" placeholder="请输入订单号"></el-input>
            </div>
            <div class="form-item" style="margin-right: 30px">
                <span>付款状态</span>
                <el-select v-model="payStatus" name="payStatus" style="width: 120px" size="small" class="get-method" placeholder="全部">
                    <el-option v-for="item in payStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </div>
            <div class="form-item" style="margin-right: 30px">
                <span>订单状态</span>
                <el-select v-model="orderStatus" name="orderStatus" style="width: 120px" size="small" class="get-method" placeholder="全部">
                    <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </div>
            <div class="form-item" style="margin-right: 30px">
              <span>收货人</span>
              <el-input size="small" style="width: 150px" v-model="consignee" name="keyword" placeholder="请输入收货人名称"></el-input>
            </div>
            <div class="form-item">
              <el-button
                  class="search-btn"
                  @click="
                          currenPage = 1
                          getOrderList()
                      "
              >
                <img src="~img/alibaba/search.png" />搜索</el-button
              >
            </div>
        </div>
        <div class="table">
            <el-table ref="multipleTable" :data="orderList" :row-key="getRowKey" @select-all="selectAll" @selection-change="setCheck" :header-cell-style="{ background: 'rgb(250,250,250)', color: '#333333' }">
                <el-table-column type="selection" width="50" align="center" :reserve-selection="true"> </el-table-column>
                <el-table-column prop="order_no" label="订单号" align="center" width="150px"> </el-table-column>
                <el-table-column label="下单时间" align="center">
                    <template slot-scope="scope">
                        {{ $util.timeStampTurnTime(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="order_money" label="订单应付金额" align="center"> </el-table-column>
                <el-table-column prop="pay_price" label="已付金额" align="center" width="100px"> </el-table-column>
                <el-table-column label="未付金额" align="center" width="100px">
                    <template slot-scope="scope">
                        {{ (scope.row.order_money - scope.row.pay_price).toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column label="订单状态" align="center" width="200px">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order_status == -1">无效订单</span>
                        <span v-else-if="scope.row.order_status == 10">已完成</span>
                        <template v-else>
                          <span>{{ scope.row.confirm_status_name }}</span> |
                          <span>{{ scope.row.pay_status_name }}</span> |
                          <span>{{ scope.row.delivery_status_name }}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span class="control" @click="orderDetail(scope.row)">查看</span>
                        <span class="control"  v-if="scope.row.confirm_status == 0" @click="orderCancel(scope.row)">取消</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="jump">
            <el-pagination class="jump-count" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
        </div>
        <div class="order">
            <el-button @click="getMergeOrder" style="background: #ed6030"><img src="../../assets/images/alibaba/excel.png" />合并订单</el-button>
            <el-button @click="exportTable"><img src="../../assets/images/alibaba/excel.png" />导出Excel</el-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import {apiExportOrderList, apiYuOrderList, mergeOrder} from "@/api/order/order"
import orderMethod from "@/utils/orderMethod"
import { info, nickName, headImg } from "@/api/member/info"
import Config from "@/utils/config";

export default {
    name: "order_list",
    components: {},
    data: () => {
        return {
            select_all:false,
            act: 0,
            payStatus: "all",
            orderStatus: "all",
            loading: true,
            orderList: [],
            currentPage: 1,
            pageSize: 7,
            total: 0,
            yes: true,
            payStatusList: [
                { label: "全部", value: "all" },
                { label: "未付款", value: "unpaid" },
                { label: "已付款", value: "paid" }
            ],
            selectOrders:[],
            orderStatusList: [
                { label: "全部订单", value: "all_order" },
                { label: "未确认", value: "order_status_unconfirmed" },
                { label: "已确认", value: "order_status_confirmed" },
                { label: "未付款", value: "order_status_unpaid" },
                { label: "未发货", value: "order_status_unshipped" },
                { label: "已发货", value: "order_status_shipped" },
                { label: "未完成", value: "order_status_unover" },
                { label: "已完成", value: "order_status_over" },
                { label: "无效订单", value: "order_status_invalid" },
                // { label: "未确认", value: "unconfirmed" },
                // { label: "已确认", value: "confirmed" },
                // { label: "配货中", value: "distribution" },
                // { label: "已发货", value: "shipped" },
                // { label: "已收货", value: "delivery_finish" },
                // { label: "已完成", value: "over" },
                // { label: "无效订单", value: "invalid" }
            ],
            multipleSelection: [],
            keyword: "",
            consignee: "",
            memberInfo: {
              all_order:"",
              //未确认
              order_status_unconfirmed: "",
              //已确认
              order_status_confirmed:"",
              //未付款
              order_status_unpaid: "",
              //未发货
              order_status_unshipped:"",
              //已发货
              order_status_shipped:"",
              //未完成
              order_status_unover:"",
              //已完成
              order_status_over:"",
              //无效订单
              order_status_invalid:"",





                unconfirmed: "", //未确认订单
                unpaid: "", //未付款订单
                unshipped: "", //未发货订单
                uncomplete: "", //未完成订单
                // cartCount: "", //购物车商品
                favorites: "", //收藏夹商品数
                fivemonth: "", //近5个月完成订单
                allmoney: "" //已完成订单金额
            },
            topList: "all", //全部订单
            topConfirmed: "unenter", //未确认
            topUnpaid: "unpaid", //未付款
            topUnshipped: "waitsend", //未发货
            topUncomplete: "uncomter", //未完成
            mergeList: [] //合并订单
        }
    },
    mixins: [orderMethod],
    created() {
        this.payStatus = this.$route.query.status || "all"
        this.orderStatus = this.$route.query.order_status || "all_order"
        this.keyword = this.$route.query.status
        console.log("当前状态", this.orderStatus, this.$route.query.order_status, this.$route.query);
        this.getOrderList()
        this.getInfo()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"])
    },
    // mounted() {
    //     let self = this
    //     setTimeout(function () {
    //         self.yes = false
    //     }, 300)
    // },
    methods: {
        getInfo() {
            info()
                .then((res) => {
                    if (res.code == 0) {
                        //全部
                        this.memberInfo.all_order                 = res.data.membwe_order_status.all_order;
                        //未确认
                        this.memberInfo.order_status_unconfirmed  = res.data.membwe_order_status.order_status_unconfirmed;
                        //已确认
                        this.memberInfo.order_status_confirmed    = res.data.membwe_order_status.order_status_confirmed;
                        //未付款
                        this.memberInfo.order_status_unpaid       = res.data.membwe_order_status.order_status_unpaid;
                        //未发货
                        this.memberInfo.order_status_unshipped    = res.data.membwe_order_status.order_status_unshipped;
                        //已发货
                        this.memberInfo.order_status_shipped      = res.data.membwe_order_status.order_status_shipped;
                        //未完成
                        this.memberInfo.order_status_unover       = res.data.membwe_order_status.order_status_unover;
                        //已完成
                        this.memberInfo.order_status_over         = res.data.membwe_order_status.order_status_over;
                        //无效订单
                        this.memberInfo.order_status_invalid      = res.data.membwe_order_status.order_status_invalid;



                        //代发货
                        this.memberInfo.unconfirmed = res.data.membwe_order_status.unconfirmed;
                        //已发货
                        this.memberInfo.confirmed = res.data.membwe_order_status.confirmed;
                        //已完成
                        this.memberInfo.distribution = res.data.membwe_order_status.distribution;
                        //未确认
                        this.memberInfo.shipped = res.data.membwe_order_status.shipped;
                        //未付款
                        this.memberInfo.delivery_finish = res.data.membwe_order_status.delivery_finish;
                        //未完成
                        this.memberInfo.over = res.data.membwe_order_status.over;
                        //未完成
                        this.memberInfo.invalid = res.data.membwe_order_status.invalid;

                        //this.memberInfo.unconfirmed = res.data.membwe_order_status.virify_order
                        //this.memberInfo.unpaid = res.data.membwe_order_status.wait_pay_order
                        //this.memberInfo.unshipped = res.data.membwe_order_status.wait_fa_order
                        //this.memberInfo.uncomplete = res.data.membwe_order_status.wait_yu_order

                        this.memberInfo.favorites = res.data.membwe_order_status.collection_goods
                        this.memberInfo.fivemonth = res.data.membwe_order_status.five_wan_order
                        this.memberInfo.allmoney = res.data.membwe_order_status.finish_order_price
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        getMergeOrder() {
            let arr = []
            let order_merge = []
            for (let i in this.selectOrders) {
                arr.push(this.selectOrders[i].order_id)
            }
            for (let i in this.selectOrders) {
                order_merge.push(this.selectOrders[i].order_status_name)
                if (order_merge[i] != "未确认") {
                    this.open()
                    return
                }
            }
            if (this.selectOrders.length == 1) {
                this.open1()
                return
            }
            let brr = []
            brr.order_id = arr
            // return false
            mergeOrder(brr)
                .then((res) => {
                    if (res.code == 0) {
                        this.getOrderList()
                    }
                    // this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        open() {
            this.$alert("仅支持订单状态为待审核的订单", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        open1() {
            this.$alert("请选择多个订单", "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                    // this.$message({
                    //     type: "info",
                    //     message: `action: ${action}`
                    // })
                }
            })
        },
        mergeTable() {
            this.pageSize = this.total
            // const this = this
            let xlsx = require("xlsx")
            let tableData = [
                ["序号", "订单号", "下单时间", "订单应付金额", "实付金额", "订单状态"] //导出表头
            ] // 表格表头
            this.orderList.forEach((item, index) => {
                let rowData = []
                //导出内容的字段
                rowData = [index + 1, item.order_no, this.$util.timeStampTurnTime(item.create_time), item.goods_money, item.pay_money, item.order_status_name]
                tableData.push(rowData)
            })
            let ws = xlsx.utils.aoa_to_sheet(tableData)
            let wb = xlsx.utils.book_new()
            xlsx.utils.book_append_sheet(wb, ws, "合并订单") // 工作簿名称
            xlsx.writeFile(wb, "合并订单.xlsx") // 保存的文件名
            this.pageSize = 7 // 还原表格长度
        },
        changePage(currentPage) {
            this.currentPage = currentPage
        },
        async exportTable() {
          const _this = this;

          this.loading = true;
          let order_no = '';
          let tag = '';
          if (this.selectOrders.length > 0) {
            this.selectOrders.forEach((item, index) => {
              order_no += tag + item.order_no;
              tag = ',';
            });
          }

          //调整为后端导出
          apiExportOrderList({
            page: this.currentPage,
            page_size: this.pageSize,
            paystatus: this.payStatus,
            orderStatus: this.orderStatus,
            keyword: this.keyword,
            consignee: this.consignee,
            order_no: order_no,
            export:true
          }).then((res) => {
            console.log("订单导出", res);
            if (res.code >= 0) {
              this.loading = false
              window.open(Config.imgDomain+res.data.path, '_blank');
            } else {
              this.$message({
                message: "未获取到订单信息",
                type: "warning",
                duration: 2000
              })
            }
          }).catch((err) => {
            this.loading = false
            this.$message.error({
              message: err.message,
              duration: 2000
            })
          })
          return;



          let xlsx = require("xlsx")
          let tableData = [
            ["订单号", "下单时间", "订单应付金额", "已付金额", "未付金额", "订单状态"] //导出表头
          ]
          if (this.select_all) {
            //全部导出
            let currentPage = 1;
            let pageSize    = 500;
            let orderStatus = '';
            let paystatus   = '';
            switch (this.act) {
              case 0:
                orderStatus = paystatus = "all";
                break;
              case 1:
                paystatus = "all";
                orderStatus = "unenter";
                break;
              case 2:
                paystatus ="unpaid";
                orderStatus = "all";
                break;
              case 3:
                paystatus ="all";
                orderStatus = "waitsend";
                break;
              case 4:
                paystatus ="all";
                orderStatus = "uncomter";
                break;
            }
            //开始循环导出订单
            let total, export_total = 0;
            do {
              this.loading = true;
              await apiYuOrderList({
                page: currentPage,
                page_size: pageSize,
                paystatus: paystatus,
                order_status: orderStatus
              }).then((res) => {
                let list = []
                if (res.code == 0 && res.data) {
                  list = res.data.list
                  total = res.data.count
                }
                // 表格表头
                list.forEach((item, index) => {
                  let rowData = [];
                  var status = '';
                  if (item.order_status == -1) {
                     status = "无效订单";
                  } else if(item.order_status == 10) {
                     status = "已完成";
                  } else {
                    status = item.confirm_status_name+"|"+item.pay_status_name+"|"+item.delivery_status_name;
                  }
                  //导出内容的字段
                  rowData = [
                      item.order_no,
                      _this.$util.timeStampTurnTime(item.create_time),
                      parseFloat(item.goods_money),
                      parseFloat(item.pay_money),
                      parseFloat(item.pay_price) - parseFloat(item.pay_price),
                      status
                  ]
                  tableData.push(rowData)
                  export_total++;
                })
                currentPage ++;
                this.loading = false;
              }).catch((res) => {
                this.loading = false;
                console.log("导出失败!", res)
              })
            } while (export_total < total);
          } else if (this.selectOrders.length > 0) {
            // 表格表头
            this.selectOrders.forEach((item, index) => {
              let rowData = [];
              var status = '';
              if (item.order_status == -1) {
                status = "无效订单";
              } else if(item.order_status == 10) {
                status = "已完成";
              } else {
                status = item.confirm_status_name+"|"+item.pay_status_name+"|"+item.delivery_status_name;
              }
              //导出内容的字段
              rowData = [
                  item.order_no,
                  _this.$util.timeStampTurnTime(item.create_time),
                  item.goods_money,
                  item.pay_price,
                  parseFloat(item.goods_money) - parseFloat(item.pay_price),
                  status
              ]
              console.log("导出数据",rowData);
              tableData.push(rowData)
            })
          } else {
            // 表格表头
            this.orderList.forEach((item, index) => {
              let rowData = [];
              var status = '';
              if (item.order_status == -1) {
                status = "无效订单";
              } else if(item.order_status == 10) {
                status = "已完成";
              } else {
                status = item.confirm_status_name+"|"+item.pay_status_name+"|"+item.delivery_status_name;
              }
              //导出内容的字段
              rowData = [
                  item.order_no,
                  _this.$util.timeStampTurnTime(item.create_time),
                  item.goods_money,
                  item.pay_price,
                  parseFloat(item.goods_money) - parseFloat(item.pay_price),
                  status
              ]
              tableData.push(rowData)
            })
          }
          let ws = xlsx.utils.aoa_to_sheet(tableData)
          let wb = xlsx.utils.book_new()
          xlsx.utils.book_append_sheet(wb, ws, "订单列表") // 工作簿名称
          xlsx.writeFile(wb, "订单列表.xlsx") // 保存的文件名
        },
        handlePageChange() {
            this.getOrderList()
        },
        //操作全选
        selectAll(e){
          this.select_all = true;
        },
        // 选中订单
        setCheck(e) {
            if(e.length == this.orderList.length) {
              this.select_all = true;
            } else {
              this.select_all = false;
            }
            this.selectOrders = e;
        },
        handleClick(tab, event) {
            this.currentPage = 1
            this.orderStatus = tab.name
            this.refresh()
        },
        getOrderList(order_status) {
            if (order_status) {
              this.orderStatus = order_status;
            }
            console.log("订单状态", this.orderStatus)
            apiYuOrderList({
                page: this.currentPage,
                page_size: this.pageSize,
                paystatus: this.payStatus,
                orderStatus: this.orderStatus,
                keyword: this.keyword,
                consignee: this.consignee
            })
                .then((res) => {
                    let list = []
                    if (res.code == 0 && res.data) {
                        list = res.data.list
                        this.total = res.data.count
                    }
                    this.orderList = list
                    this.loading = false
                })
                .catch((res) => {
                    this.loading = false
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.refresh()
        },
        refresh() {
            this.loading = true
            this.getOrderList()
        },
        operation(action, orderData) {
            let index = this.status
            switch (action) {
                case "orderPay": // 支付
                    this.orderPay(orderData)
                    break
                case "orderClose": //关闭
                    this.orderClose(orderData.order_id, () => {
                        this.refresh()
                    })
                    break
                case "memberTakeDelivery": //收货
                    this.orderDelivery(orderData.order_id, () => {
                        this.refresh()
                    })
                    break
                case "trace": //查看物流
                    this.$router.push({
                        path: "/member/logistics",
                        query: {
                            order_id: orderData.order_id
                        }
                    })
                    break
                case "memberOrderEvaluation": //评价
                    this.$router.pushToTab({
                        path: "/evaluate",
                        query: {
                            order_id: orderData.order_id
                        }
                    })
                    break
            }
        },
        // 取消订单
        orderCancel(order) {
            this.orderClose(order.order_id, () => {
                this.getOrderDetail()
            })
        },
        orderDetail(data) {
            switch (parseInt(data.order_type)) {
                case 2:
                    // 自提订单
                    this.$router.push({
                        path: "/member/order_detail_pickup",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                case 3:
                    // 本地配送订单
                    this.$router.push({
                        path: "/member/order_detail_local_delivery",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                case 4:
                    // 虚拟订单
                    this.$router.push({
                        path: "/member/order_detail_virtual",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                default:
                    this.$router.push({
                        path: "/member/order_detail2",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
            }
        },
        imageError(orderIndex, goodsIndex) {
            this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage
        },
        getRowKey(row) {
          return row.order_id;
        }
    }
}
</script>

<style lang="scss" scoped>
.act {
    color: #ed6030;
}
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .switchTab {
            display: flex;
            align-items: center;

            .tab-item {
                font-size: $ns-font-size-lg;
                font-weight: 400;
                padding: 0 5px 0 5px;
                border-right: 1px solid #e1e1e1;
                height: 20px;
                display: block;
                line-height: 20px;
                cursor: pointer;

                &:last-child {
                    border: 0;
                }
            }
            .number {
                color: #ed6030;
            }
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .table {
        width: 100%;
        margin-top: 30px;

        .control {
            font-size: $ns-font-size-base;
            color: $base-color;
            cursor: pointer;

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}
.el-button {
    height: 40px;
    background-color: #2d9842;
    color: #ffffff;
    font-size: $ns-font-size-lg;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border-radius: 20px;

    img {
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }
}
.jump {
    // text-align: right;
    margin-top: 20px;
    .jump-count {
        // padding-right: 20px;
        float: right;
        // margin-left: 80%;
    }

    .sure {
        margin: 0px;
        font-size: 13px;
        padding: 7px 10px;
        height: 28px;
        border-radius: 3px;
        background-color: #fff;
        color: #606266;
        line-height: 13px;
        margin-top: 2px;
        // border: none;
        border: 1px solid #dcdfe6;
    }
}
.order {
    float: left;
}
.el-table-column--selection .cell {
    padding: 0px 10px !important;
}
.top1 {
    width: 950px !important;
}
</style>
